import { useEffect, useRef } from "react";

import { appConfig } from "../../config/Config";
import { AES } from "crypto-js";

import { HttpMainApi } from "../../interface/main-api";

import Stack from "@mui/material/Stack";
import Toast from "../../utils/Toast";

import "./Login.css";

const HomeshopLogin = () => {
  const mainApi = new HttpMainApi();
  const toastRef: any = useRef();

  const getUserKey = (encryptedKey: string) => {
    // http://localhost:3000/login?requestKey=192837465197320147481428385651973&time=20240719
    const before = "1928374651973";
    const after = "1428385651973";
    const result = encryptedKey.replace(before, "").replace(after, "");
    return result;
  };

  // rds 통신해서 람다 호출
  const check_user_rds = async (key_id: string) => {
    const res = await mainApi.login_proc_id(key_id);
    if ("" + res.code === "200") {
      if (res.response.result === "success") procSesson(res.response.session);
    }
  };

  // url 가져오기
  const stateInfo: URLSearchParams = new URLSearchParams(
    window.location.search
  );

  // const decryptKeyTest = (encryptedKey: string) => {
  //   // https://www.ai-lewis.com/login?requestKey=iEn8YWWRX9Gc%2bk4Rp6g43g%3d%3d&time=PhdFa721nR5Mc0CPlEAFrg%3d%3d
  //   // https://localhost/login?requestKey=iEn8YWWRX9Gc%2bk4Rp6g43g%3d%3d&time=PhdFa721nR5Mc0CPlEAFrg%3d%3d
  //   const a = "12345678901234567890Hyundai12345"; // 32바이트인데 단순히 이것만 가지고 되는지 확인
  //   const finalSecretKey = CryptoJS.enc.Utf8.parse(a);
  //   const b = "1234567890hyundai";
  //   const ivKey = CryptoJS.enc.Utf8.parse(b);
  //   // decrypt
  //   const decipher = CryptoJS.AES.decrypt(encryptedKey, finalSecretKey, {
  //     mode: CryptoJS.mode.CBC,
  //     padding: CryptoJS.pad.Pkcs7,
  //     iv: ivKey,
  //   });
  //   console.log(decipher);
  //   console.log("decipher : " + decipher);
  //   const decryptedKeyData = decipher.toString(CryptoJS.enc.Utf8);
  //   console.log("decrypt and utf-8 : " + decryptedKeyData);
  //   return decryptedKeyData;
  // };

  const procSesson = (sessionData: any) => {
    // // toastRef.current?.toast("로그인 성공", "info", 3000, { vertical: "bottom", horizontal: "right" });
    const sessionObj: any = {
      id: sessionData.id,
      userName: sessionData.user_name,
      userInfo1: sessionData.user_info1,
      userInfo2: sessionData.user_info2,
      userInfo3: sessionData.user_info3,
      userInfo4: sessionData.user_info4,
      userType: sessionData.user_type,
      textLong: sessionData.TEXT_LONG,
      expire: Date.now() + 1000 * 60 * 60 * 24, // 24시간
    };
    const objString = JSON.stringify(sessionObj);
    const encObjString = AES.encrypt(objString, appConfig.seed).toString();
    window.localStorage.setItem(appConfig.sessionName, encObjString);

    // 아이디 저장 기억하기
    const rememberIdObj: any = {
      id: sessionData.id,
      remember: "TRUE",
    };
    const objRememberId = JSON.stringify(rememberIdObj);
    if (objRememberId) {
      window.localStorage.setItem(appConfig.rememberidSSName, objRememberId);
    }
    window.location.href = "/agree";
  };

  useEffect(() => {
    // none querystring
    window.history.replaceState({}, "", window.location.pathname);

    // requestKey
    const keyInfo = stateInfo.get("requestKey");
    const time = stateInfo.get("time");
    if (keyInfo) {
      const customKey = getUserKey(keyInfo);
      let date = new Date();
      let month =
        date.getMonth() + 1 < 10
          ? "0" + (date.getMonth() + 1)
          : date.getMonth() + 1;
      let days = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      let today = `${date.getFullYear()}${month}${days}` + "";
      if (time === today) {
        // 로그인 진행
        check_user_rds(customKey);
      } else {
        //날짜 확인후 주석
        // alert("아이디와 비밀번호로 로그인 진행해주세요");
      }
    }
  }, []);

  return (
    <div className="login-root">
      <div className="login-wrapper">
        <div className="login-box">
          <Stack spacing={2} direction="column">
            <h2>
              <img
                src="/images/login-logo-a.png"
              />
            </h2>
            <p>AI 카피라이터 Aizac에 오신걸 환영해요.</p>
            <div className="form-wrap">
                {" "}
                H-art를 통해서 접속해주시기 바랍니다.
            </div>
          </Stack>
        </div>
        <div className="copy-warapper">
          <p className="copyright">© Hyundai Homeshopping. All rights reserved.</p>
        </div>
      </div>
      <Toast ref={toastRef} />
    </div>
  );
};

export default HomeshopLogin;
