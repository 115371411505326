import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";

import { HttpMainApi, RePostQuestion } from "../../interface/main-api";
import { userState } from "../../interface/MainInterface";
import LoadingCircle from "../../utils/LoadingCircle";
import Toast from "../../utils/Toast";
import { appConfig } from "../../config/Config";
import "./QnaList.css";
interface propsType {
  userState: userState;
}

const mainApi = new HttpMainApi();

const QnaDetail = (props: propsType) => {
  let { state } = useLocation();
  let navigate = useNavigate();

  const toastRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const [qInfo, setQinfo] = useState<any>();
  const [qnaList, setQnaList] = useState<any>([]);

  const [title, setTitle] = useState("");
  const [author, setAuthor] = useState("");
  const [stateText, setStateText] = useState("답변예정");
  const [createTimestamp, setCreateTimestamp] = useState("");

  const fncGetQnaDetail = useCallback(async (_qInfo: any) => {
    setIsLoading(true);
    const res = await mainApi.get_qna_detail_list(_qInfo.QSEQ);
    if ("" + res.code === "200") {
      setQnaList(res.response.qna_list);
      setTitle(res.response.qna_list[0].TITLE);
      setAuthor(res.response.qna_list[0].AUTHOR);
      setCreateTimestamp(res.response.qna_list[0].CREATE_TIMESTAMP);
      if (res.response.qna_list[0].ANSWER_YN === "Y") setStateText("답변완료");
      else setStateText("답변예정");
    } else {
      toastRef.current?.toast("조회 중 에러가 발생했습니다.", "error", 3000, {
        vertical: "top",
        horizontal: "center",
      });
    }
    setIsLoading(false);
  }, []);

  const fncGoQnaList = useCallback(() => {
    navigate("/qna");
  }, [navigate]);

  useEffect(() => {
    if (qInfo) {
      fncGetQnaDetail(qInfo); // 한번만 실행
    }
  }, [qInfo, fncGetQnaDetail]);

  useEffect(() => {
    setQinfo(state.qInfo); // 한번만 실행해야해서 별도 state로 관리한다.
  }, [state]);

  const [addQuestion, setAddQuestion] = useState("");

  const fncAddQuestion = useCallback(async () => {
    if (addQuestion === "" || addQuestion.trim() === "") {
      toastRef.current?.toast("추가 문의할 내용을 입력하세요.", "error", 3000, {
        vertical: "top",
        horizontal: "center",
      });
      return;
    }

    const param: RePostQuestion = {
      originQseq: qInfo.QSEQ,
      title: "추가문의",
      content: addQuestion,
      userState: props.userState,
    };

    setIsLoading(true);

    const res = await mainApi.repost_question(param);
    if ("" + res.code === "200") {
      toastRef.current?.toast("추가문의 등록을 완료했습니다.", "success", 3000, {
        vertical: "top",
        horizontal: "center",
      });
      fncGetQnaDetail(qInfo);
    } else {
      toastRef.current?.toast("저장 중 에러가 발생했습니다.", "error", 3000, { vertical: "top", horizontal: "center" });
    }
    setIsLoading(false);
  }, []);

  return (
    <>
      <Box sx={{ p: 0.5 }}>
        <Stack direction="column" spacing={2}>
          <Box component={"div"} sx={{ marginTop: "0.7rem" }}></Box>
          <Box component={"div"}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Stack direction="row" spacing={1}>
                  <CardMedia
                    component="img"
                    image={"/images/icon-other-qna.png"}
                    sx={{
                      width: "31px",
                      height: "38px",
                      objectFit: "contain",
                      filter: "drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.15))",
                    }}
                  />
                  <Typography sx={{ display: "inline", fontWeight: "bold", fontSize: "1.5rem" }} component="span">
                    문의 내용 확인
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </Box>
          <Paper sx={{ p: 2, borderRadius: "1rem" }} elevation={3}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={1} sx={{ display: "flex", justifyContent: "center" }}>
                <Typography
                  sx={{ display: "inline", fontWeight: "bold", fontSize: "1rem", color: "#6d89ed" }}
                  component="span"
                >
                  {stateText}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={8} sx={{ display: "flex", justifyContent: "flex-start" }}>
                <Typography sx={{ display: "inline", fontWeight: "bold", fontSize: "1rem" }} component="span">
                  {title}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={1} sx={{ display: "flex", justifyContent: "center" }}>
                <Typography sx={{ display: "inline", fontWeight: "bold", fontSize: "1rem" }} component="span">
                  {author}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2} sx={{ display: "flex", justifyContent: "center" }}>
                <Typography sx={{ display: "inline", fontWeight: "bold", fontSize: "1rem" }} component="span">
                  {createTimestamp}
                </Typography>
              </Grid>
            </Grid>
          </Paper>
          <Paper sx={{ p: 2, borderRadius: "1rem" }} elevation={3}>
            {qnaList.map((qnaInfo: any, index: number) => (
              <div key={qnaInfo.QSEQ}>
                <Box className="question-root">
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={10} sx={{ display: "flex", justifyContent: "left" }}>
                      <Stack direction={"row"} spacing={2}>
                        <Typography className="question-icon">Q</Typography>
                        <Typography className="question-contents">{qnaInfo.CONTENT}</Typography>
                      </Stack>
                    </Grid>
                    <Grid item xs={12} sm={2} sx={{ display: "flex", justifyContent: "center" }}>
                      <Typography className="answer-contents">{qnaInfo.CREATE_TIMESTAMP}</Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Divider />
                {index === qnaList.length - 1 && (qnaInfo.A_CONTENT === "" || qnaInfo.A_CONTENT === null) ? (
                  <Box className="answer-waiting">
                    <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
                      <Typography className="answer-contents font-bold">
                        답변 예정입니다. 잠시만 기다려주세요...
                      </Typography>
                    </Box>
                  </Box>
                ) : (
                  <>
                    <Box className="answer-root">
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={10} sx={{ display: "flex", justifyContent: "left" }}>
                          <Stack direction={"row"} spacing={2}>
                            <Typography className="answer-icon">A</Typography>
                            <Typography className="answer-contents">
                              안녕하세요 AI 카피라이터 {appConfig.systemName}입니다.
                            </Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={12} sm={2} sx={{ display: "flex", justifyContent: "center" }}>
                          <Typography className="answer-contents">{qnaInfo.A_CREATE_TIMESTAMP}</Typography>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sx={{ display: "flex", justifyContent: "left", pl: "1.8rem" }}>
                        <Typography className="answer-contents">{qnaInfo.A_CONTENT}</Typography>
                      </Grid>
                    </Box>
                    <Divider />
                    {index === qnaList.length - 1 && qInfo.ANSWER_YN === "Y" && (
                      <Box sx={{ mt: 1 }}>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                            <TextField
                              label="추가문의"
                              multiline
                              rows={3}
                              fullWidth
                              value={addQuestion}
                              autoComplete="off"
                              inputProps={{ enterKeyHint: "Enter" }}
                              onChange={(e) => {
                                setAddQuestion(e.target.value);
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                            <Button
                              variant="outlined"
                              color="primary"
                              onClick={() => {
                                fncAddQuestion();
                              }}
                            >
                              추가문의등록
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    )}
                  </>
                )}
              </div>
            ))}
          </Paper>
          <Box component={"div"}>
            <Grid container spacing={2}>
              <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-start" }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    fncGoQnaList();
                  }}
                >
                  목록
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Stack>
        <LoadingCircle loading={isLoading} />
        <Toast ref={toastRef} />
      </Box>
    </>
  );
};

export default QnaDetail;
