import { useCallback, useEffect, useRef, useState } from "react";
import dayjs from "dayjs";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import { HttpMainApi, GetMyHistoryListParam } from "../../interface/main-api";
import { userState } from "../../interface/MainInterface";
import LoadingCircle from "../../utils/LoadingCircle";
import Toast from "../../utils/Toast";

import MyCopuTootip from "./MyCopuTootip";
import HistoryResult from "../copyWriter/HistoryResult";

import "./MyCopyList.css";

interface propsType {
  userState: userState;
}

const mainApi = new HttpMainApi();

const MyCopyList = (props: propsType) => {
  const toastRef: any = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const [productText, setProductText] = useState("");
  const [fromDateValue, setFromDateValue] = useState<any>(dayjs().add(-3, "M").startOf("M"));
  const [toDateValue, setToDateValue] = useState<any>(dayjs());

  const [showResult, setShowResult] = useState(false);
  const [historyList, setHistoryList] = useState<any>([]);
  const [historyAddList, setHistoryAddList] = useState(1);

  const validateDate = useCallback(() => {
    if (!fromDateValue) return false;
    if (!toDateValue) return false;
    if (toDateValue.diff(fromDateValue) < 0) return false;
    return true;
  }, [fromDateValue, toDateValue]);

  const fncGetMyCopyList = async () => {
    if (!validateDate()) {
      toastRef.current?.toast(
        "날짜 양식을 확인하세요. 검색 시작일이 검색 종료일보다 다음날 일수 없습니다.",
        "error",
        3000,
        {
          vertical: "top",
          horizontal: "center",
        }
      );
      return;
    }

    const param: GetMyHistoryListParam = {
      userId: props.userState.id,
      dataText: productText,
      fromDateText: dayjs(fromDateValue).format("YYYYMMDD"),
      toDateText: dayjs(toDateValue).format("YYYYMMDD"),
    };

    setShowResult(false);
    setIsLoading(true);
    const res = await mainApi.get_my_history_list(param);
    if ("" + res.code === "200") {
      setShowResult(true);
      setHistoryAddList(10);
      setHistoryList(res.response.data_lists);
    } else {
      console.error("fncGetMyCopyList ERROR : ", res.response.error_msg);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fncGetMyCopyList();
  }, []);

  return (
    <>
      <Box sx={{ p: 0.5 }}>
        <Stack direction="column" spacing={2}>
          {/* 카피소재영역 */}
          <Box component={"div"} sx={{ marginTop: "0.7rem" }}></Box>
          <Box component={"div"}>
            <Stack direction="row" spacing={1}>
              <CardMedia
                component="img"
                image={"/images/icon-cpwt-new.png"}
                sx={{
                  width: "31px",
                  height: "38px",
                  objectFit: "contain",
                  filter: "drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.15))",
                }}
              />
              <Typography sx={{ display: "inline", fontWeight: "bold", fontSize: "1.5rem" }} component="span">
                내가 만든 카피를 검색해 보세요
              </Typography>
            </Stack>
          </Box>
          <Paper sx={{ p: 2, borderRadius: "1rem", mb: "3rem !important" }} elevation={3}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <Stack direction="row" spacing={0.5}>
                  <Typography component="span" className="typo-h4">
                    제품 / 브랜드
                  </Typography>
                  <MyCopuTootip />
                </Stack>
                <Box component="div" className="copy-input">
                  <TextField
                    id="txtProduct"
                    size="small"
                    value={productText}
                    autoComplete="on"
                    onChange={(e) => {
                      setProductText(e.target.value);
                    }}
                    onKeyUp={(e) => {
                      if (isLoading) return;
                      if (e.key === "Enter") fncGetMyCopyList();
                      e.preventDefault();
                    }}
                    fullWidth
                  />
                </Box>
              </Grid>
              <Grid item xs={12} lg={4}>
                <Stack direction="row" spacing={0.5}>
                  <Typography component="span" className="typo-h4">
                    생성 일자
                  </Typography>
                </Stack>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Stack spacing={2} direction="row">
                    <Box component="div" className="copy-input full-width">
                      <DatePicker
                        label="From"
                        value={fromDateValue}
                        onChange={(newValue) => setFromDateValue(newValue)}
                        slotProps={{ textField: { size: "small", autoComplete: "off", fullWidth: true } }}
                      />
                    </Box>
                    <Box component="div" className="copy-input full-width">
                      <DatePicker
                        label="To"
                        value={toDateValue}
                        onChange={(newValue) => setToDateValue(newValue)}
                        slotProps={{ textField: { size: "small", autoComplete: "off", fullWidth: true } }}
                      />
                    </Box>
                  </Stack>
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} lg={2}>
                <Stack direction="row" spacing={0.5}>
                  <Typography component="span" className="typo-h4 color-white h-23">
                    {" "}
                  </Typography>
                </Stack>
                <Button variant="contained" fullWidth sx={{ fontSize: "1rem" }} onClick={fncGetMyCopyList}>
                  찾아볼게요
                </Button>
              </Grid>
            </Grid>
          </Paper>
          {/* 카피소재영역 */}
          {/* My History */}
          <Box component={"div"} sx={{ ...(!showResult && { display: "none" }) }}>
            <Stack direction="row" spacing={1}>
              <CardMedia
                component="img"
                image={"/images/icon-timeline-new.png"}
                sx={{
                  width: "31px",
                  height: "38px",
                  objectFit: "contain",
                  filter: "drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.15))",
                }}
              />
              <Typography sx={{ display: "inline", fontWeight: "bold", fontSize: "1.5rem" }} component="span">
                내가 만든 카피
              </Typography>
            </Stack>
          </Box>
          <Box component={"div"}>
            <Paper
              sx={{
                p: 2,
                borderRadius: "1rem",
                position: "relative",
                ...(showResult ? { display: "flex" } : { display: "none" }),
              }}
              elevation={3}
            >
              <Stack direction="column" spacing={1.5} sx={{ width: "100%" }}>
                {historyList.slice(0, historyAddList).map((item: any, index: number) => (
                  <HistoryResult
                    key={item.range}
                    userState={props.userState}
                    pk={item.pk}
                    range={item.range}
                  />
                ))}
                <Box sx={{ width: "100%", textAlign: "center" }}>
                  <Button
                    variant="outlined"
                    sx={{ fontSize: "1rem", backgroundColor: "#f8f9fa", color: "#1f2d3d", borderColor: "#f8f9fa" }}
                    onClick={() => {
                      setHistoryAddList(historyAddList + 5);
                    }}
                  >
                    더보기 +
                  </Button>
                </Box>
              </Stack>
            </Paper>
          </Box>
          {/* My History */}
        </Stack>
        <LoadingCircle loading={isLoading} />
        <Toast ref={toastRef} />
      </Box>
    </>
  );
};

export default MyCopyList;
