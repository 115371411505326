import React, { useRef, useState, useEffect, FC } from "react";
import { Skeleton } from "@mui/material";
import axios from "axios";
import { appConfig } from "../../config/Config";

import "./CopyWriter.css";

interface TruncatedTextProps {
  text: string;
  maxLength: number;
}

const NaverKeywordRolling = () => {
  const [isCommomLoading, setIsCommomLoading] = useState(false);
  const [commonKeywords, setCommonKeywords] = useState<string[]>([]);
  const [commonKeywordsOld, setCommonKeywordsOld] = useState<string[]>([]);
  const [newKeywords, setNewKeywords] = useState<string[]>([]);

  const [open, setOpen] = useState(false);
  const searchListRef = useRef<HTMLUListElement>(null);
  const [index, setIndex] = useState(0);

  const [isActive, setIsActive] = useState(false);
  const [selectedOption, setSelectedOption] = useState("패션의류");
  const options = [
    "패션의류",
    "패션잡화",
    "화장품/미용",
    "디지털/가전",
    "가구/인테리어",
    "출산/육아",
    "식품",
    "스포츠/레저",
    "생활/건강",
    "여가/생활편의",
    "면세점",
    "도서",
  ];
  const toggleDropdown = () => setIsActive(!isActive);
  const handleOptionClick = (option: string) => {
    setSelectedOption(option);
    setIsActive(false);
  };

  const get_naver_keyword_list = async () => {
    try {
      setIsCommomLoading(true);
      setCommonKeywords([]);
      const data = {
        command: "get_data_list",
        category: selectedOption,
      };

      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const res = await axios.post(appConfig.NaverKeywordApiUrl, data, config);

      if (res.status === 200) {
        setCommonKeywords(res.data.response.data_list[0].keywords);
        setCommonKeywordsOld(res.data.response.data_list[1].keywords);
        const newKeywords = res.data.response.data_list[0].keywords.map(
          (keyword: string, index: number) => {
            const oldIndex =
              res.data.response.data_list[1].keywords.indexOf(keyword);
            let trend = "maintain";
            let newData = "";
            if (oldIndex === -1) {
              trend = "rise"; // 새로 추가된 키워드
              newData = "new";
            } else if (index < oldIndex) {
              trend = "rise";
              newData = "";
            } else if (index > oldIndex) {
              trend = "drop";
              newData = "";
            }

            return {
              rank: index + 1,
              keyword,
              trend,
              newData,
            };
          }
        );
        setNewKeywords(newKeywords);
        setIsCommomLoading(false);
      } else {
        console.error("Error occurred while saving title.");
        setIsCommomLoading(false);
      }
    } catch (error) {
      console.error("Error occurred while saving title:", error);
      setIsCommomLoading(false);
    }
  };

  useEffect(() => {
    get_naver_keyword_list();
  }, [selectedOption]);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => {
        if (newKeywords.length === 0) {
          return prevIndex;
        }
        const newIndex = (prevIndex + 1) % newKeywords.length;
        return newIndex;
      });
    }, 2000);

    return () => clearInterval(interval);
  }, [newKeywords.length]);

  useEffect(() => {
    if (searchListRef.current) {
      const itemHeight = searchListRef.current.children[0]?.clientHeight || 0;
      if (index === 0) {
        searchListRef.current.style.transition = "none";
        searchListRef.current.style.transform = `translateY(0px)`;
      } else {
        searchListRef.current.style.transition = "transform 0.5s ease";
        searchListRef.current.style.transform = `translateY(-${
          index * itemHeight
        }px)`;
      }
    }
  }, [index]);

  const TruncatedText: FC<TruncatedTextProps> = ({ text, maxLength }) => {
    const truncatedText =
      text.length > maxLength ? `${text.substring(0, maxLength)}...` : text;

    return <span>{truncatedText}</span>;
  };
  return (
    <>
      <div className="select-wrapper">
        <div className={`select_wrap ${isActive ? "active" : ""}`}>
          <ul className="default_option" onClick={toggleDropdown}>
            <li>
              <div className="option">
                <div className="icon" />
                <p>{selectedOption}</p>
              </div>
            </li>
          </ul>
          {isActive && (
            <ul className="select_ul">
              {options.map((option, index) => (
                <li key={index} onClick={() => handleOptionClick(option)}>
                  {option}
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>

      <div
        className={`search-wrapper ${open ? "open-all" : ""}`}
        onClick={() => setOpen(!open)}
      >
        <div className="search-container">
          <p>
            <img src="/images/icon-hotrank.png" alt="Hot Rank Icon" />
          </p>

          <div className="search-rolling">
            <ul id="search-list" ref={searchListRef}>
              {newKeywords.map((item: any, index) => (
                <li key={index}>
                  {isCommomLoading ? (
                    <>
                      <Skeleton animation="wave" width={180} />
                    </>
                  ) : (
                    <>
                      <strong>{item.rank}</strong>
                      <TruncatedText text={item.keyword} maxLength={9} />
                      <span className="new-rank">{item.newData}</span>
                      <i className={`rank-icon ${item.trend}`} />
                    </>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
        <ul className="rank-list-all">
          {newKeywords.map((item: any, index) => (
            <li key={index}>
              <strong>{item.rank}</strong>
              <TruncatedText text={item.keyword} maxLength={12} />
              <span className="new-rank">{item.newData}</span>
              <i className={`rank-icon ${item.trend}`} />
            </li>
          ))}
          <li>
            <p>
              <img src="/images/icon-ndatalab.png" />
              네이버 데이터랩 제공
            </p>
          </li>
        </ul>
      </div>
    </>
  );
};

export default NaverKeywordRolling;
