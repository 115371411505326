import React, { forwardRef, useImperativeHandle, Ref, useState } from "react";
import Stack from "@mui/material/Stack";
import { Box, Skeleton } from "@mui/material";
import Chip from "@mui/material/Chip";
import axios from "axios";
import { appConfig } from "../../config/Config";

import "./CopyWriter.css";

interface RecommendwordProps {
  productText: string;
  setKeywordText: (keyword: string) => void;
  keywordText: string;
  teamType: string;
}
// common_recommend_v1 : 공통 광고 키워드
// catagory_recommend_A : 기본 광고 키워드
// catagory_recommend_B : SNS 광고 키워드
// catagory_recommend_C : 소재 광고 키워드
// catagory_recommend_D : 숫자강조 광고 키워드
// catagory_recommend_E : 연예인 광고 키워드
// catagory_recommend_F : 셀링포인트 광고 키워드

// catagory_recommend_H : 기획전 제안  키워드

const Recommendword = forwardRef(
  (
    props: RecommendwordProps,
    ref: Ref<{
      get_recommendword: () => void;
      get_common_recommendword: () => void;
      get_season_recommendword: () => void;
    }>
  ) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isCommomLoading, setIsCommomLoading] = useState(false);
    const [commonKeywords, setCommonKeywords] = useState<string[]>([]);
    const [seasonKeywords, setSeasonKeywords] = useState<string[]>([]);

    const [keywords, setKeywords] = useState<string[]>([]);

    const get_common_recommendword = async () => {
      try {
        setIsCommomLoading(true);
        setCommonKeywords([]);
        const data = {
          command: "common_recommend_v1",
          productText: props.productText,
        };

        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const res = await axios.post(appConfig.RecommendApiUrl, data, config);
        if (res.status === 200) {
          setCommonKeywords(res.data.body.keyword);
          setIsCommomLoading(false);
        } else {
          console.error("Error occurred while saving title.");
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error occurred while saving title:", error);
        setIsLoading(false);
      }
    };

    const get_season_recommendword = async () => {
      try {
        setIsCommomLoading(true);
        setSeasonKeywords([]);
        const data = {
          command: "season_recommend_v1",
          productText: props.productText,
        };

        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const res = await axios.post(appConfig.RecommendApiUrl, data, config);
        if (res.status === 200) {
          setSeasonKeywords(res.data.body.keyword);
          setIsCommomLoading(false);
        } else {
          console.error("Error occurred while saving title.");
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error occurred while saving title:", error);
        setIsLoading(false);
      }
    };

    const get_recommendword = async () => {
      try {
        setIsLoading(true);
        setKeywords([]);
        let newTeamType = props.teamType;
        if (["H", "I", "J"].includes(props.teamType)) {
          newTeamType = "H";
        }

        const data = {
          command: "catagory_recommend_" + newTeamType,
          productText: props.productText,
        };

        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const res = await axios.post(appConfig.RecommendApiUrl, data, config);
        if (res.status === 200) {
          setKeywords(res.data.body.keyword);
          setIsLoading(false);
        } else {
          setKeywords([]);
          console.error("Error occurred while saving title.");
          setIsLoading(false);
        }
      } catch (error) {
        console.error("Error occurred while saving title:", error);
        setIsLoading(false);
      }
    };

    useImperativeHandle(ref, () => ({
      get_recommendword,
      get_season_recommendword,
      get_common_recommendword,
    }));

    const handleClick = (keyword: string) => {
      const keywordsArray = props.keywordText
        ? props.keywordText.split(", ")
        : [];
      if (!keywordsArray.includes(keyword)) {
        const newKeywordText =
          keywordsArray.length > 0
            ? `${props.keywordText}, ${keyword}`
            : keyword;
        props.setKeywordText(newKeywordText);
      }
    };
    return (
      <>
        <Stack direction="row" spacing={1} sx={{ mt: 1, mb: 1, mr: 1 }}>
          {isLoading || isCommomLoading ? (
            <>
              <Chip label={<Skeleton animation="wave" width={40} />} />
              <Chip label={<Skeleton animation="wave" width={40} />} />
              <Chip label={<Skeleton animation="wave" width={40} />} />
              <Chip label={<Skeleton animation="wave" width={40} />} />
              <Chip
                style={{ backgroundColor: "#E6F7E9" }}
                label={<Skeleton animation="wave" width={40} />}
              />
              <Chip
                style={{ backgroundColor: "#E6F7E9" }}
                label={<Skeleton animation="wave" width={40} />}
              />
              <Chip
                style={{ backgroundColor: "#E6F7E9" }}
                label={<Skeleton animation="wave" width={40} />}
              />
            </>
          ) : (
            <>
              {commonKeywords.map((keyword) => (
                <Chip
                  key={keyword}
                  label={keyword}
                  onClick={() => handleClick(keyword)}
                />
              ))}
              {seasonKeywords.map((keyword) => (
                <Chip
                  key={keyword}
                  label={keyword}
                  onClick={() => handleClick(keyword)}
                />
              ))}
              {keywords.map((keyword) => (
                <Chip
                  style={{ backgroundColor: "#E6F7E9" }}
                  key={keyword}
                  label={keyword}
                  onClick={() => handleClick(keyword)}
                />
              ))}
            </>
          )}
        </Stack>
      </>
    );
  }
);

export default Recommendword;
