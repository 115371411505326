import Stack from "@mui/material/Stack";
import ErrorIcon from "@mui/icons-material/Error";
import Typography from "@mui/material/Typography";
import { appConfig } from "../../config/Config";

import "./CopyWriter.css";

const NotiMemeKeyword = () => {
  return (
    <>
      <Stack direction="row">
        <ErrorIcon className="copy-info-caution-icon" />
        <Stack direction="column">
          <Typography color="inherit" className="copy-info-caution">
            과도한 유행어 사용은 잘못된 해석, 법적 문제, 브랜드 이미지 손상 
            등이 발생할 수 있으니 주의 바랍니다.
          </Typography>
        </Stack>
      </Stack>
    </>
  );
};

export default NotiMemeKeyword;
