export const appConfig = {
  projectName: "lewis02v1Prod",
  systemNameEng: "Aizac",
  systemName: "아이작",
  systemNameExam: "아이작이",
  mainApiUrl:
    "https://70l998bh5d.execute-api.ap-northeast-2.amazonaws.com/prod/adminMain",
  testApiUrl:
    "https://70l998bh5d.execute-api.ap-northeast-2.amazonaws.com/prod/adminMain",
  RecommendApiUrl:
    "https://q44g6b2tyvko7tinfpfhqcmxny0vogdh.lambda-url.ap-northeast-2.on.aws",
  NaverKeywordApiUrl:
    "https://fiiwbhjsghq47ffpluxkkjfuv40uvamp.lambda-url.ap-northeast-2.on.aws",
  sessionName: "lewis02ss", // 사이트마다 달라야 함
  rememberidSSName: "lws02rmbrid", // 사이트마다 달라야 함
  seed: "34t9urgj0gt9gj045475turiogj439t034gjg45t459grgji5490gurtgnfgkb903-3-d;lhgmrtmhl;kghnmg.nmv/l",
  sessionTimeout: 1000 * 60 * 60 * 3, // 180분
  qnaInfoSSName: "lewis02v1QnaInfo",
};
