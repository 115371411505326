/* eslint-disable jsx-a11y/anchor-is-valid */
import { useNavigate } from "react-router-dom";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { appConfig } from "../../config/Config";
import "./Agree.css";

interface propsType {}

const Agree = (props: propsType) => {
  let navigate = useNavigate();

  const handleClose = () => {
    window.localStorage.removeItem(appConfig.sessionName);
    navigate("/login");
  };
  const handleAgree = () => {
    navigate("/");
  };

  return (
    <>
      <div className="modal-agree">
        <div className="modal-wrap">
          <div className="modal-box">
            <h5>사용 동의</h5>
            <ol>
              <li>
                업무적인 용도 이외의 목적으로 aizac 서비스를 이용하는
                행위는 엄격히 금지합니다.
              </li>
              <li>
                사용자는 aizac를 악의적인 목적으로 이용할 수 없습니다. 악의적
                사용이란 aizac를 사용하는 과정에서 고의적인 값을 입력하여 차별적
                의견 또는 부당한 가치 판단 등 편향적인 결과값을 발생시키는 것이
                대표적이며, 그 밖에도 악의적인 의도를 가지고 aizac를 사용하여
                발생시키는 문제를 포함합니다.
              </li>
              <li>
                aizac 콘텐츠에 대한 사실 관계 및 법적, 윤리적 문제에 대한 확인
                및 검증에 대한 모든 책임은 사용자에게 있습니다.
              </li>
              <li>
                aizac에서 만든 콘텐츠(사용자 결과물)가 타인의 저작권에 위반되는
                등의 문제가 발생했을 경우, 이에 대한 모든 책임은 회사에 있지
                않고 이를 외부에서 사용한 해당 사용자에게 있습니다.
              </li>
              <li>
                회사는 서비스 품질 관리를 위해 생성된 콘텐츠에 대하여 정기적인
                사후 검토 작업을 진행합니다.
              </li>
              <li>
                회사의 정책 또는 법규를 위반한다고 합리적으로 판단되는 콘텐츠는
                삭제할 수 있습니다.
              </li>
            </ol>
            <div className="agree-btn-wrap">
              <Stack spacing={2} direction="row">
                <Button
                  variant="outlined"
                  className="disagree"
                  onClick={handleClose}
                >
                  동의하지 않습니다
                </Button>
                <Button
                  variant="contained"
                  className="agree"
                  onClick={handleAgree}
                >
                  동의합니다
                </Button>
              </Stack>
            </div>
          </div>
        </div>
        <div className="bg-black"></div>
      </div>
    </>
  );
};

export default Agree;
