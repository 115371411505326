import { useState, forwardRef, useImperativeHandle } from "react";
import Snackbar, { SnackbarOrigin } from "@mui/material/Snackbar";
import MuiAlert, { AlertProps, AlertColor } from "@mui/material/Alert";

export interface State extends SnackbarOrigin {
  open: boolean;
}

interface propsType {}

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const Toast = (props: propsType, ref: any) => {
  const [state, setState] = useState<State>({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = state;

  // const [open, setOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastDuration, setToastDuration] = useState(3000);
  const [serverrity, setServerrity] = useState<AlertColor>("info");
  const [newPosition, setNewPosition] = useState<any>();

  const toast = (inMessage: string, inServerity: string, inDuration: number, position: any) => {
    let tmpserverity: any = "info";
    if (inServerity !== "" && inServerity !== undefined) {
      tmpserverity = inServerity;
    }
    setToastMessage((toastMessage) => inMessage);
    setServerrity((serverrity) => tmpserverity);
    setToastDuration((toastDuration) => inDuration);
    setNewPosition(position);
    // setOpen(true);
    setState({ open: true, vertical: position.vertical, horizontal: position.horizontal });
  };

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    setState({ open: false, vertical: newPosition.vertical, horizontal: newPosition.horizontal });
  };

  useImperativeHandle(ref, () => ({
    toast,
  }));

  return (
    <>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={toastDuration}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={`${serverrity}`} sx={{ width: "100%" }}>
          {toastMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default forwardRef(Toast);
