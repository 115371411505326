import * as React from "react";
import { styled } from "@mui/material/styles";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import CardMedia from "@mui/material/CardMedia";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Collapse from "@mui/material/Collapse";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";

import { userState } from "../interface/MainInterface";
import { appConfig } from "../config/Config";

import "./LeftMenu.css";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

interface propsType {
  userState: userState;
  drawerWidth: number;
  largeDrawerWidth: number;
  selectedMenu: string;
  move: any;
}

export interface IMenuItem {
  name: string;
  activeIcon: string;
  deactiveIcon: string;
  path: string;
  children?: Array<IMenuItem>;
}

export const MENU: IMenuItem[] = [
  {
    name: "카피라이터",
    activeIcon: "/images/icon-side-cpwt.png",
    deactiveIcon: "/images/icon-side-cpwt-off.png",
    path: "/multicopyWriter",
  },
  {
    name: "MYPAGE",
    activeIcon: "/images/icon-side-promo-on.png",
    deactiveIcon: "/images/icon-side-promo.png",
    path: "mypage00",
    children: [
      {
        name: "나의 카피 모음",
        activeIcon: "",
        deactiveIcon: "",
        path: "/myCopyList",
      },

      {
        name: "좋아요 모음",
        activeIcon: "",
        deactiveIcon: "",
        path: "/myLikeList",
      },

      {
        name: "모든 카피 모음",
        activeIcon: "",
        deactiveIcon: "",
        path: "/totalCopyList",
      },

      // {
      //   name: "문의내역",
      //   activeIcon: "",
      //   deactiveIcon: "",
      //   path: "/qna",
      // },
    ],
  },
];

export const ADMIN_MENU: IMenuItem[] = [
  {
    name: "ADMIN",
    activeIcon: "/images/icon-side-menual-on.png",
    deactiveIcon: "/images/icon-side-menual.png",
    path: "setting00",
    children: [
      // {
      //   name: "통계 관리",
      //   activeIcon: "",
      //   deactiveIcon: "",
      //   path: "/dailyStatistics"
      // },
      // {
      //   name: "치환 문자 관리",
      //   activeIcon: "",
      //   deactiveIcon: "",
      //   path: "/mngReplace",
      // },
      {
        name: "회원 관리",
        activeIcon: "",
        deactiveIcon: "",
        path: "/signUpUser"
      }
    ],
  },
];

export const SUPER_ADMIN_MENU: IMenuItem[] = [
  {
    name: "ADMIN",
    activeIcon: "/images/icon-side-menual-on.png",
    deactiveIcon: "/images/icon-side-menual.png",
    path: "setting00",
    children: [
      // {
      //   name: "통계 관리",
      //   activeIcon: "",
      //   deactiveIcon: "",
      //   path: "/dailyStatistics"
      // },
      // {
      //   name: "공지사항 관리",
      //   activeIcon: "",
      //   deactiveIcon: "",
      //   path: "/mngNotice",
      // },
      // {
      //   name: "회원 관리",
      //   activeIcon: "",
      //   deactiveIcon: "",
      //   path: "/mngUser"
      // },
      {
        name: "모델 설정 관리",
        activeIcon: "",
        deactiveIcon: "",
        path: "/modelSelect",
      },

      {
        name: "모델 관리",
        activeIcon: "",
        deactiveIcon: "",
        path: "/mngModel",
      }
    ],
  },
];

const LeftMenu = (props: propsType) => {
  const [expandMenu, setExpandMenu] = React.useState<any>([]);

  const handleExpand = (menuPath: string) => {
    let temp = { ...expandMenu };
    temp[menuPath] = !temp[menuPath];
    setExpandMenu(temp);
  };

  React.useEffect(() => {
    for (const menu of MENU) {
      if (menu.children) {
        for (const child of menu.children) {
          if (props.selectedMenu === child.path) {
            let temp = { ...expandMenu };
            temp[menu.path] = true;
            setExpandMenu(temp);
          }
        }
      }
    }
  }, [props.selectedMenu]);

  function BrandLogo() {
    // 나머지는 루이스로
    return (
      <a href="/" className="brand-link">
        <img
          src="/images/logo-mini.png"
          alt="Logo"
          className="brand-image elevation-3"
        />
        {props.drawerWidth === props.largeDrawerWidth && (
          <img
            src="/images/logo-txt-a.png"
            alt="Logo"
            className="brand-image elevation-3"
          />
        )}
      </a>
    );
  }

  return (
    <>
      <DrawerHeader
        sx={{
          justifyContent: "flex-start",
          minHeight: "70px",
          width: `${props.largeDrawerWidth}px`,
        }}
      >
        {/* Brand Logo */}
        <BrandLogo />
      </DrawerHeader>
      <List key="header" sx={{ width: `${props.largeDrawerWidth}px` }}>
        <ListItem
          alignItems="flex-start"
          sx={{ minBlockSize: "77px", pl: "9px" }}
        >
          <ListItemAvatar>
            <Avatar alt="사용자" src="/images/user2-160x160.jpg" />
          </ListItemAvatar>
          <ListItemText
            primary={
              props.drawerWidth === props.largeDrawerWidth && (
                <React.Fragment>
                  <Typography
                    sx={{
                      display: "inline",
                      color: "#ffffff",
                      fontWeight: "700",
                      fontSize: "1.2rem",
                    }}
                    component="span"
                  >
                    {props.userState.userName}
                  </Typography>
                </React.Fragment>
              )
            }
            secondary={
              props.drawerWidth === props.largeDrawerWidth && (
                <React.Fragment>
                  <Typography
                    sx={{ display: "inline", color: "#AAAAAA" }}
                    component="span"
                    variant="body2"
                  >
                    {props.userState.userInfo2}
                  </Typography>
                </React.Fragment>
              )
            }
          />
        </ListItem>
      </List>
      <List key={"3344"}>
        {MENU.map((menuInfo, index) => (
          <div key={menuInfo.name + "" + index}>
            <ListItem
              disablePadding
              sx={{
                minHeight: "51px",
                ...(props.drawerWidth !== props.largeDrawerWidth
                  ? { width: "60px" }
                  : { width: "235px" }),
              }}
            >
              <ListItemButton
                className="LeftDrawer"
                selected={menuInfo.path === props.selectedMenu}
                onClick={() => {
                  menuInfo.children
                    ? handleExpand(menuInfo.path)
                    : props.move(menuInfo.path);
                }}
              >
                <ListItemIcon sx={{ color: "#ffffff" }}>
                  <CardMedia
                    component="img"
                    image={
                      menuInfo.path === props.selectedMenu
                        ? menuInfo.activeIcon
                        : menuInfo.deactiveIcon
                    }
                    sx={{ width: "26px", height: "26px", objectFit: "contain" }}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={
                    props.drawerWidth === props.largeDrawerWidth && (
                      <Typography
                        className={
                          menuInfo.path === props.selectedMenu
                            ? "menu-text-active"
                            : "menu-text"
                        }
                      >
                        {menuInfo.name}
                      </Typography>
                    )
                  }
                />
                {menuInfo.children &&
                  (expandMenu[menuInfo.path] ? <ExpandLess /> : <ExpandMore />)}
              </ListItemButton>
            </ListItem>
            {menuInfo.children && (
              <Collapse
                in={expandMenu[menuInfo.path]}
                timeout="auto"
                unmountOnExit
              >
                {menuInfo.children.map((childInfo, idx) => (
                  <ListItem
                    key={menuInfo.name + "" + childInfo.name + "" + idx}
                    disablePadding
                    sx={{
                      minHeight: "48px",
                      ...(props.drawerWidth !== props.largeDrawerWidth
                        ? { width: "60px", paddingLeft: "10px" }
                        : { width: "235px", paddingLeft: "20px" }),
                    }}
                  >
                    <ListItemButton
                      className="LeftDrawer"
                      selected={childInfo.path === props.selectedMenu}
                      onClick={() => {
                        props.move(childInfo.path);
                      }}
                    >
                      <ListItemIcon sx={{ color: "#ffffff", minWidth: "30px" }}>
                        <RadioButtonUncheckedIcon
                          sx={{
                            fontSize: "1rem",
                            ...(childInfo.path === props.selectedMenu
                              ? { color: "#212121" }
                              : { color: "#fff" }),
                          }}
                        />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          props.drawerWidth === props.largeDrawerWidth && (
                            <Typography
                              className={
                                childInfo.path === props.selectedMenu
                                  ? "sub-menu-text-active"
                                  : "sub-menu-text"
                              }
                            >
                              {childInfo.name}
                            </Typography>
                          )
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                ))}
              </Collapse>
            )}
          </div>
        ))}

        {props.userState.userType === "2" &&
          ADMIN_MENU.map((menuInfo, index) => (
            <div key={menuInfo.name + "" + index}>
              <ListItem
                disablePadding
                sx={{
                  minBlockSize: "48px",
                  ...(props.drawerWidth !== props.largeDrawerWidth
                    ? { width: "60px" }
                    : { width: "235px" }),
                }}
              >
                <ListItemButton
                  className="LeftDrawer"
                  selected={menuInfo.path === props.selectedMenu}
                  onClick={() => {
                    menuInfo.children
                      ? handleExpand(menuInfo.path)
                      : props.move(menuInfo.path);
                  }}
                >
                  <ListItemIcon sx={{ color: "#ffffff" }}>
                    <CardMedia
                      component="img"
                      image={
                        menuInfo.path === props.selectedMenu
                          ? menuInfo.activeIcon
                          : menuInfo.deactiveIcon
                      }
                      sx={{
                        width: "26px",
                        height: "26px",
                        objectFit: "contain",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      props.drawerWidth === props.largeDrawerWidth && (
                        <Typography
                          className={
                            menuInfo.path === props.selectedMenu
                              ? "menu-text-active"
                              : "menu-text"
                          }
                        >
                          {menuInfo.name}
                        </Typography>
                      )
                    }
                  />
                  {menuInfo.children &&
                    (expandMenu[menuInfo.path] ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    ))}
                </ListItemButton>
              </ListItem>
              {menuInfo.children && (
                <Collapse
                  in={expandMenu[menuInfo.path]}
                  timeout="auto"
                  unmountOnExit
                >
                  {menuInfo.children.map((childInfo, idx) => (
                    <ListItem
                      key={menuInfo.name + "" + childInfo.name + "" + idx}
                      disablePadding
                      sx={{
                        minHeight: "48px",
                        ...(props.drawerWidth !== props.largeDrawerWidth
                          ? { width: "60px", paddingLeft: "10px" }
                          : { width: "235px", paddingLeft: "20px" }),
                      }}
                    >
                      <ListItemButton
                        className="LeftDrawer"
                        selected={childInfo.path === props.selectedMenu}
                        onClick={() => {
                          props.move(childInfo.path);
                        }}
                      >
                        <ListItemIcon
                          sx={{ color: "#ffffff", minWidth: "30px" }}
                        >
                          <RadioButtonUncheckedIcon
                            sx={{
                              fontSize: "1rem",
                              ...(childInfo.path === props.selectedMenu
                                ? { color: "#212121" }
                                : { color: "#fff" }),
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            props.drawerWidth === props.largeDrawerWidth && (
                              <Typography
                                className={
                                  childInfo.path === props.selectedMenu
                                    ? "sub-menu-text-active"
                                    : "sub-menu-text"
                                }
                              >
                                {childInfo.name}
                              </Typography>
                            )
                          }
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </Collapse>
              )}
            </div>
          ))}


        {props.userState.userType === "1" &&
          SUPER_ADMIN_MENU.map((menuInfo, index) => (
            <div key={menuInfo.name + "" + index}>
              <ListItem
                disablePadding
                sx={{
                  minBlockSize: "48px",
                  ...(props.drawerWidth !== props.largeDrawerWidth
                    ? { width: "60px" }
                    : { width: "235px" }),
                }}
              >
                <ListItemButton
                  className="LeftDrawer"
                  selected={menuInfo.path === props.selectedMenu}
                  onClick={() => {
                    menuInfo.children
                      ? handleExpand(menuInfo.path)
                      : props.move(menuInfo.path);
                  }}
                >
                  <ListItemIcon sx={{ color: "#ffffff" }}>
                    <CardMedia
                      component="img"
                      image={
                        menuInfo.path === props.selectedMenu
                          ? menuInfo.activeIcon
                          : menuInfo.deactiveIcon
                      }
                      sx={{
                        width: "26px",
                        height: "26px",
                        objectFit: "contain",
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      props.drawerWidth === props.largeDrawerWidth && (
                        <Typography
                          className={
                            menuInfo.path === props.selectedMenu
                              ? "menu-text-active"
                              : "menu-text"
                          }
                        >
                          {menuInfo.name}
                        </Typography>
                      )
                    }
                  />
                  {menuInfo.children &&
                    (expandMenu[menuInfo.path] ? (
                      <ExpandLess />
                    ) : (
                      <ExpandMore />
                    ))}
                </ListItemButton>
              </ListItem>
              {menuInfo.children && (
                <Collapse
                  in={expandMenu[menuInfo.path]}
                  timeout="auto"
                  unmountOnExit
                >
                  {menuInfo.children.map((childInfo, idx) => (
                    <ListItem
                      key={menuInfo.name + "" + childInfo.name + "" + idx}
                      disablePadding
                      sx={{
                        minHeight: "48px",
                        ...(props.drawerWidth !== props.largeDrawerWidth
                          ? { width: "60px", paddingLeft: "10px" }
                          : { width: "235px", paddingLeft: "20px" }),
                      }}
                    >
                      <ListItemButton
                        className="LeftDrawer"
                        selected={childInfo.path === props.selectedMenu}
                        onClick={() => {
                          props.move(childInfo.path);
                        }}
                      >
                        <ListItemIcon
                          sx={{ color: "#ffffff", minWidth: "30px" }}
                        >
                          <RadioButtonUncheckedIcon
                            sx={{
                              fontSize: "1rem",
                              ...(childInfo.path === props.selectedMenu
                                ? { color: "#212121" }
                                : { color: "#fff" }),
                            }}
                          />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            props.drawerWidth === props.largeDrawerWidth && (
                              <Typography
                                className={
                                  childInfo.path === props.selectedMenu
                                    ? "sub-menu-text-active"
                                    : "sub-menu-text"
                                }
                              >
                                {childInfo.name}
                              </Typography>
                            )
                          }
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </Collapse>
              )}
            </div>
          ))}
      </List>
    </>
  );
};

export default LeftMenu;
