import { useEffect, useState } from "react";

import { userState, inputList } from "../../interface/MainInterface";
import { HttpMainApi, GetLikeInfoParam, PutLikeInfoParam } from "../../interface/main-api";

interface propsType {
  pk: string;
  range: string;
  category: string;
  subRange: string;
  userState: userState;
  inputList: inputList;
  callBack: any;
  likeActive: boolean[];
  onLikeClick: (index: number, active: boolean) => void;
  contentIndex: number;
  clickLikeUnlikeFlag: boolean;
  setClickLikeUnlikeFlag: any;
}

const mainApi = new HttpMainApi();

const MultiLike = (props: propsType) => {
  const [className, setClassName] = useState("fas fa-icon fa-thumbs-up");

  useEffect(() => {
    if (props.clickLikeUnlikeFlag) {
      setEffectLike();
    }
    props.setClickLikeUnlikeFlag(false);
  }, [props.likeActive[props.contentIndex]]);

  const setEffectLike = async () => {
    const getparam: GetLikeInfoParam = {
      userState: props.userState,
      range: props.range,
    };
    let isActive = false;
    const getres = await mainApi.get_like_info(getparam);
    if ("" + getres.code === "200") {
      isActive = getres.response.result === "Y";
    } else {
      console.error("setEffectLike get ERROR : ", getres.response.error_msg);
    }
    if(isActive !== props.likeActive[props.contentIndex]) {
      const putparam: PutLikeInfoParam = {
        pk: props.pk,
        range: props.range,
        category: props.category,
        subRange: props.subRange,
        userState: props.userState,
        inputList: props.inputList,
      };
      const putres = await mainApi.put_like(putparam);
      if ("" + putres.code === "200") {
        isActive = putres.response.result === "Y";
        setClassName(isActive ? "fas fa-icon fa-thumbs-up active" : "fas fa-icon fa-thumbs-up");
      } else {
        console.error("setEffectLike put ERROR : ", putres.response.error_msg);
      }
    }
  };

  const setClickLike = async () => {
    props.setClickLikeUnlikeFlag(true);
    const param: PutLikeInfoParam = {
      pk: props.pk,
      range: props.range,
      category: props.category,
      subRange: props.subRange,
      userState: props.userState,
      inputList: props.inputList,
    };
    const res = await mainApi.put_like(param);
    if ("" + res.code === "200") {
      const isActive = res.response.result === "Y";
      setClassName(isActive ? "fas fa-icon fa-thumbs-up active" : "fas fa-icon fa-thumbs-up");
      props.onLikeClick(props.contentIndex, isActive);
      // props.callBack();
    } else {
      console.error("setClickLike ERROR : ", res.response.error_msg);
    }
  };

  const get_like_info = async () => {
    const param: GetLikeInfoParam = {
      userState: props.userState,
      range: props.range,
    };
    const res = await mainApi.get_like_info(param);
    if ("" + res.code === "200") {
      const isActive = res.response.result === "Y";
      setClassName(isActive ? "fas fa-icon fa-thumbs-up active" : "fas fa-icon fa-thumbs-up");
      // props.onLikeClick(props.contentIndex, isActive);
    } else {
      console.error("get_like_info ERROR : ", res.response.error_msg);
    }
  };

  useEffect(() => {
    get_like_info();
  }, []); //[] 변경시마다  작동

  return <i className={className} onClick={setClickLike} />;
};

export default MultiLike;
