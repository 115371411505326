import { useEffect, useState } from "react";

import { userState, inputList } from "../../interface/MainInterface";
import { HttpMainApi, GetUnLikeInfoParam, PutUnLikeInfoParam } from "../../interface/main-api";

interface propsType {
  pk: string;
  range: string;
  category: string;
  subRange: string;
  userState: userState;
  inputList: inputList;
  unlikeActive: boolean[];
  onUnLikeClick: (index: number, active: boolean) => void;
  contentIndex: number;
}

const mainApi = new HttpMainApi();

const UnLike = (props: propsType) => {
  const [className, setClassName] = useState("fas fa-icon fa-thumbs-down");

  useEffect(() => {
    setEffectUnLike();
  }, [props.unlikeActive[props.contentIndex]]);

  const setEffectUnLike = async () => {
    const getparam: GetUnLikeInfoParam = {
      userState: props.userState,
      range: props.range,
    };
    let isActive = false;
    const getres = await mainApi.get_unlike_info(getparam);
    if ("" + getres.code === "200") {
      isActive = getres.response.result === "Y";
    } else {
      console.error("setEffectUnLike get ERROR : ", getres.response.error_msg);
    }
    if(isActive !== props.unlikeActive[props.contentIndex]) {
      const putparam: PutUnLikeInfoParam = {
        pk: props.pk,
        range: props.range,
        category: props.category,
        subRange: props.subRange,
        userState: props.userState,
        inputList: props.inputList,
      };
      const putres = await mainApi.put_unlike(putparam);
      if ("" + putres.code === "200") {
        isActive = putres.response.result === "Y";
        setClassName(isActive ? "fas fa-icon fa-thumbs-down active" : "fas fa-icon fa-thumbs-down");
      } else {
        console.error("setEffectUnLike put ERROR : ", putres.response.error_msg);
      }
    }
  };

  const setClickUnLike = async () => {
    const param: PutUnLikeInfoParam = {
      pk: props.pk,
      range: props.range,
      category: props.category,
      subRange: props.subRange,
      userState: props.userState,
      inputList: props.inputList,
    };
    const res = await mainApi.put_unlike(param);
    if ("" + res.code === "200") {
      const isActive = res.response.result === "Y";
      setClassName(isActive ? "fas fa-icon fa-thumbs-down active" : "fas fa-icon fa-thumbs-down");
      props.onUnLikeClick(props.contentIndex, isActive);
    } else {
      console.error("setClickUnLike ERROR : ", res.response.error_msg);
    }
  };

  const get_unlike_info = async () => {
    const param: GetUnLikeInfoParam = {
      userState: props.userState,
      range: props.range,
    };
    const res = await mainApi.get_unlike_info(param);
    if ("" + res.code === "200") {
      const isActive = res.response.result === "Y";
      setClassName(isActive ? "fas fa-icon fa-thumbs-down active" : "fas fa-icon fa-thumbs-down");
      // props.onUnLikeClick(props.contentIndex, isActive);
    } else {
      console.error("get_unlike_info ERROR : ", res.response.error_msg);
    }
  };

  useEffect(() => {
    get_unlike_info();
  }, []); //[] 변경시마다  작동

  return <i className={className} onClick={setClickUnLike} />;
};

export default UnLike;
